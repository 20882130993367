<template>
  <div class="CreatorsAside" :id="id">
    <div class="CreatorsAsideContent" v-if="showCreators">
      <div class="CreatorsAsideTitle">
        <LargeButton :label="$locale['explore_creators']" icon="stars" @click="$router.push('/?feed=explore-creators')" />
      </div>
      <Spacer num="1" />
      <div class="CreatorsAsideList">
        <CreatorExploreCard class="ExploreCreatorsCard" v-for="item in items" :key="item._id" :creator="item" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
export default {
  mixins: [FeedList],
  components: {
    CreatorExploreCard: () => import("@/components/creator/CreatorExploreCard.vue"),
  },
  data: function() {
    return {
      id: Go.uuid(),
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/explore`,
      storeId: "aside_creators",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 5,
      query: `rand=true`,
      delay: 200,
      onePage: true,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  computed: {
    showCreators: function() {
      if (this.$creator && !this.isAdminCreator()) {
        return false;
      }

      return this.$route.query.feed !== "explore-creators" && this.$route.query.view !== "statistics";
    },
    offList: function() {
      return !this.showCreators;
    },
  },
  methods: {
    init: function() {
      if (this.$screen.height >= 1050) {
        this.limit = 5;
      }

      if (this.$screen.height < 1050) {
        this.limit = 4;
      }

      if (this.$screen.height < 865) {
        this.limit = 3;
      }
    },
  },
  beforeMount: function() {
    this.init();
  },
};
</script>

<style lang="scss">
$cardHeight: 182px;
.CreatorsAside {
  padding: $mpadding;

  .CreatorsAsideContent {
    width: 100%;
  }

  .CreatorsAsideTitle {
    position: relative;
  }

  .CreatorsAsideTitle .LargeButton {
    background-color: var(--theme-background);
    border-radius: $mpadding;
    @include shadow();
  }

  .CreatorsAsideTitle .LargeButton .LargeButtonIcon {
    font-size: 150%;
  }

  .CreatorsAsideTitle .LargeButton .iconic {
    color: $primary_color;
  }

  .CreatorExploreCard {
    min-height: $cardHeight;
  }

  .CreatorExploreCard .Cover {
    height: calc(#{$cardHeight} - #{$mpadding * 1});
  }

  .CreatorExploreCard .VideoCoverButton.CircleButton {
    @include sizeFixed($mpadding * 2);
    font-size: 50%;
    @include Flex(inherit, center, center);
  }

  .CreatorExploreCard .Label {
    font-size: 80%;
    gap: $mpadding/2;
  }
}
</style>
